<template>
  <b-row>
    <b-col md="12">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="float-right mb-1"
        onclick="history.back()"
      >
        <feather-icon icon="ArrowLeftCircleIcon" />
        Retour
      </b-button>
    </b-col>
    <b-col md="12">
      <b-card>
        <b-spinner
          v-if="loader"
          style="width: 2rem; height: 2rem;"
          variant="primary"
          label="Large Spinner"
          type="grow"
          class="mt-2"
        />
        <vue-apex-charts
          v-else
          ref="realtimeChart"
          type="bar"
          height="350"
          :options="latestBarChart.chartOptions"
          :series="latestBarChart.series"
        />
        <span v-if="expertises.length===0">
          Aucun expert dans cette categorie
        </span>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive,
} from '@vue/composition-api'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import axiosClient from '@/helpers/axios'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import VueApexCharts from 'vue-apexcharts'

import {
  BButton, BRow, BCol, BCard, BSpinner,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useExpertises from '@/services/expertise/expertiseservice'
// eslint-disable-next-line import/no-cycle
import useTypeExperts from '@/services/expertise/typeExpertService'
import { $themeColors } from '@themeConfig'
// eslint-disable-next-line import/extensions
import { openDB } from './indexDB'
import apexChatData from './apexChartData'
// import BarChart from './BarChart.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BSpinner,
    // BarChart,
    VueApexCharts,
    BCard,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      loader, expertises,
    } = useExpertises()
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const {
      typeExperts, getTypeExperts, coordonnees, errors, typeExpert, updateTypeExpert,
    } = useTypeExperts()
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'libelle', label: 'Libellé', sortable: true },
      { key: 'description', label: 'Description', sortable: true },
      { key: 'actions' },
    ]
    const form = reactive({
      libelle: '',
      description: '',
    })
    /* eslint-disable global-require */

    onMounted(() => {
      getTypeExperts()
      // getAllExpertisesByStatut('Fermée', 2)
    })
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      fields,
      onFiltered,
      // latestBarChart,
      form,
      required,
      formatter,
      loader,
      expertises,
      // getByType,
      coordonnees,
      // chartDatas,
      // getAllExpertisesByStatut,
      typeExperts,
      getTypeExperts,
      errors,
      typeExpert,
      updateTypeExpert,
    }
  },

  data() {
    return {
      coordinates: [],
      apexChatData,
      loader: true,
      latestBarChart: {
        series: [
          {
            name: 'Nombre de demandes traitees',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: $themeColors.info,
          plotOptions: {
            bar: {
              horizontal: false,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            name: 'Experts',
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    console.log(router.currentRoute.params.id)
    this.getAllExpertisesByStatut('Fermée', router.currentRoute.params.id)
    console.log(this.expertises)
  },
  methods: {
    async addData(data) {
      const db = await openDB()
      const transaction = db.transaction(['data'], 'readwrite')
      const objectStore = transaction.objectStore('data')

      // Supprimez d'abord l'ancien enregistrement
      await objectStore.clear()

      // Ajoutez le nouvel enregistrement
      await objectStore.put(data)

      // Affiche un message indiquant que les données ont été ajoutées
      console.log('Les données ont été ajoutées à IndexedDB')
    },
    async fetchData() {
      const db = await openDB()
      const transaction = db.transaction(['data'], 'readonly')
      const objectStore = transaction.objectStore('data')

      const request = objectStore.getAll()

      request.onsuccess = () => {
        console.log(request.result)
        this.latestBarChart.chartOptions.xaxis.categories = request.result[0].x
        this.latestBarChart.series[0].data = request.result[0].y
        this.loader = false
        return request.result
      }

      request.onerror = event => {
        console.error('Error fetching data:', event.target.error)
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.ValidationRules.validate().then(async success => {
        if (success) {
          // Push the name to submitted names
          await this.storeTypeExpert()
          if (this.typeExpertSuccess) {
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn')
            })
          }
        }
      })
    },
    generateCoordinates(data) {
      const expertiseCounts = {}
      const experts = []
      const counts = []

      data.forEach(entry => {
        const expertId = entry.experts[0].id
        expertiseCounts[expertId] = (expertiseCounts[expertId] || 0) + 1
      })

      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const expertId in expertiseCounts) {
        const expert = data[0].experts.find(e => e.id === +expertId)
        experts.push(expert.username)
        counts.push(expertiseCounts[expertId])
      }

      // eslint-disable-next-line no-sequences
      this.chartDatas = {
        x: experts,
        y: counts,
      }
      this.addData({
        x: experts,
        y: counts,
      })
    },
    getAllExpertisesByStatut(statut, type) {
      this.loader = true
      axiosClient.get(`/get-all-expertises-by-status/${statut}`).then(response => {
        if (response.data.success === true) {
          const data = response.data.data.filter(exp => exp.type_expert_id === type)
          // eslint-disable-next-line no-const-assign
          this.generateCoordinates(data)
          this.fetchData()
        }
      })
    },
    async getByType(type) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.getAllExpertisesByStatut('Fermée', type)
      console.log(this.latestBarChart)
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
